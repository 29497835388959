import * as React from "react"
import Layout from "components/Layout";
import Seo from "components/Seo";
import Header from 'components/Header'
import CardSection from 'components/CardSection'
import HomeIcon from '@material-ui/icons/HomeOutlined';

const title = "404 Page Not Found"
const description = "There is no page at this location."

const buttons = [
  {
    text: 'Return to home',
    to: '/',
    icon: <HomeIcon />
  },
]

export default () => (
  <Layout>
    <Seo
      title={title}
      description={description}
    />
    <Header
      title={title}
      subtitle={description}
    />
    <CardSection
      buttons={buttons}
    />
  </Layout>
);
